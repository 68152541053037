<!--  -->
<template>
  <div id="testList">
    <!-- 开始考试弹框 -->
    <el-dialog
      title="请选择做题模式"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      @before-close="centerDialogVisible = false"
    >
      <div class="testType">
        <div @click="praticeType">
          <img src="@/assets/image/index2.png" alt="" />
          <p>练习模式</p>
          <p>解题思路随时查看</p>
        </div>
        <div @click="testType">
          <img src="@/assets/image/index3.png" alt="" />
          <p>考试模式</p>
          <p>须交卷方可显示答案</p>
        </div>
      </div>
    </el-dialog>
    <div class="list-banner">
      <div class="list-banner-inner">
        <div class="list-title">基于IoT平台构建智慧路灯应用</div>
        <div class="list-context">
          本实验指导用户基于华为云IoT平台，快速开发属于自己的智慧路灯应用。
        </div>
      </div>
    </div>
    <div class="list-content">
      <div class="home_left">
        <div class="left-top">
          <div v-for="item in list" :key="item.id">
            <p>
              <span class="num">{{ item.errorTotal }}</span
              ><span>{{ item.num }}</span>
            </p>
            <p>{{ item.text }}</p>
          </div>
        </div>
        <div class="left_main">
          <!-- <h2>历年真题</h2> -->
          <div class="left-bottom">
            <div class="bottomList">
              <div
                class="bottomList-item"
                v-for="(item, index) in testList"
                :key="index"
              >
                <div class="list-left">
                  <img src="@/assets/image/listlogo.png" alt="" />
                  <div class="list-left_info">
                    <p>{{ item.name }}</p>
                  </div>
                  <button @click="startTest(item)">开始考试</button>
                </div>
                <div class="test-information-center"></div>
                <div class="test-infomation-right">
                  <ul>
                    <li>
                      <p>难易程度</p>
                      <p class="test-level">
                        {{ item.difficultys }}<span>级</span>
                      </p>
                    </li>
                    <li>
                      <p>考试评分</p>
                      <div class="block">
                        <el-rate v-model="value2" :colors="colors" disabled :size="30"> </el-rate>
                      </div>
                    </li>
                    <li>
                      <p>考试时间</p>
                      <p class="test-time">
                        {{ item.durations }}<span>分钟</span>
                      </p>
                    </li>
                    <li>
                      <p>考试人数</p>
                      <p class="test-num">
                        {{ item.records_count }}<span>人</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <!-- <div class="List_left">
                  <img src="@/assets/image/listlogo.png" alt="" />
                  <div>
                    <p>{{ item.name }}</p>
                    <p>难度：{{ item.difficultys }}</p>
                  </div>
                </div>
                <button @click="startTest(item)">开始考试</button> -->
              </div>
            </div>
            <div class="more">
              <div v-show="totolBool1" class="click-more" @click="clickMore">
                查看更多
              </div>
              <div v-show="totolBool" class="none">没有更多了</div>
            </div>
          </div>
        </div>
      </div>

      <div class="home_right">
        <div class="right-img">
          <img src="../../assets/image/5.jpg" alt="" />
        </div>
        <p class="all-num">共{{ totalTest }}套试卷</p>
        <div class="right_subConMode">
          <ul>
            <li>
              <a href="#" class="index_cuo">
                <i></i>
                <span>错题集</span>
              </a>
            </li>
            <li>
              <a href="#" class="index_cuo">
                <i></i>
                <span>收藏夹</span>
              </a>
            </li>
            <li>
              <a href="#" class="index_cuo">
                <i></i>
                <span>做题记录</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      list: [
        { id: 1, errorTotal: "0", text: "错题总数", num: "道" },
        { id: 2, errorTotal: "0", text: "做题总数", num: "道" },
        { id: 3, errorTotal: "0", text: "做题时长", num: "分钟" },
      ],

      testList: [], //试卷列
      centerDialogVisible: false, //做题弹框
      rowObj: {}, //当前行数据
      pageSize: {
        //页数
        limit: 4,
        page: 1,
      },
      totolBool: false,
      totolBool1: false,
      totalTest: 0,
      value2: 0,
      colors: ["#c7000b", "#c7000b", "#c7000b"],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    testList() {
      if (this.testList.length == this.totalTest) {
        this.totolBool = true;
        this.totolBool1 = false;
      } else {
        this.totolBool = false;
        this.totolBool1 = true;
      }
    },
  },
  //方法集合
  methods: {
    startTest(item) {
      //去做题
      let _this = this;
      _this.rowObj = item;

      let obj = {
        id: _this.rowObj.id,
      };
      _this.$api.records(obj).then((res) => {
        _this.question = res.data.question_class_record;
        _this.$store.commit("changeCreated", _this.question);
      });
      _this.$store.commit("changeRowObj", _this.rowObj);
      localStorage.name = _this.rowObj.name;
      _this.centerDialogVisible = true; //弹框显示
    },
    //练习模式
    praticeType() {
       this.$router.push("/Index/Pratice");
    },
    //考试模式
    testType() {
      let _this = this;
      _this.$router.push("/Index/Notice");
    },
    clickMore() {
      let _this = this;
      if (_this.testList.length < _this.totalTest) {
        _this.pageSize.page += 1;
        _this.allList();
      } else {
        _this.totolBool = true;
      }
    },
    allList() {
      let _this = this;
      let obj = {
        "filter[parent_id]": _this.testRowObj.id,
        limit: _this.pageSize.limit,
        page: _this.pageSize.page,
      };
      _this.$api.classesindexlist(obj).then((res) => {
        _this.totalTest = res.data.lists.total;
        res.data.lists.data.forEach((v) => {
          _this.value2 = v.difficultys;

          _this.testList.push(v);
        });
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;
    _this.total = localStorage.testTotal;
    _this.testRowObj = JSON.parse(localStorage.testRowObj);
    _this.allList();
    _this.$api.index().then((res) => {
      _this.list[0].errorTotal = res.data.qcounterror;
      _this.list[1].errorTotal = res.data.qcount;
      _this.list[2].errorTotal = res.data.durations;
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
#testList {
  width: 100%;
  height: 100%;

  .list-banner {
    width: 100%;
    height: 260px;
    background: url("../../assets/image/list-banner.png") no-repeat;
    background-position: 50%;
    background-size: cover;
    .list-banner-inner {
      width: 1180px;
      margin: 0 auto;
      color: rgba(37, 43, 58, 1);
      .list-title {
        padding-left: 42px;
        font-size: 36px;
        padding-top: 40px;
        line-height: 46px;
        font-weight: 600;
      }
      .list-context {
        margin-top: 12px;
        font-size: 18px;
        line-height: 32px;
        padding-left: 42px;
      }
    }
  }
  .list-content {
    width: 1180px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    .home_left {
      height: 100%;
      width: 1180px;
      margin: 0 auto;
      margin-top: -100px;
      .left-top {
        background: #fff;
        padding: 42px 0;
        display: flex;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
        justify-content: space-between;
        display: none;
        div {
          width: 269px;
          text-align: center;
          p {
            color: #999;
            font-size: 12px;
          }
          &:nth-child(1) {
            p {
              .num {
                font-size: 46px;
                line-height: 46px;
                color: #ff6c6c;
              }
            }
          }
          &:nth-child(2) {
            p {
              .num {
                font-size: 46px;
                line-height: 46px;
                color: #ffd007;
              }
            }
          }
          &:nth-child(3) {
            p {
              .num {
                font-size: 46px;
                line-height: 46px;
                color: #9aa1ac;
              }
            }
          }
        }
      }
      .left_main {
        // background: #fff;
        // margin-top: 10px;
        // padding: 20px 30px 0 49px;
        // width: 100%;
        h2 {
          padding: 10px 10px;
          font-size: 16px;
          color: #de3833;
          width: 90px;
          border-bottom: 2px solid #de3833;
        }
        .left-bottom {
          // height: 520px;
          padding-bottom: 30px;
          // overflow: auto;

          .bottomList {
            padding: 27px 30px 21px 10px;
            .bottomList-item {
              display: flex;
              height: 140px;
              padding: 28px;
              box-sizing: border-box;
              min-width: 665px;
              background: rgba(255, 255, 255, 1);
              border: 1px solid #eee;
              border-radius: 2px 2px 0 0;
              margin-bottom: 10px;
              &:hover {
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
              }
              .list-left {
                width: 40.18%;
                min-width: 450px;
                padding-right: 30px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img {
                  width: 47px;
                  height: 50px;
                  display: none;
                }
              }
              .test-information-center {
                width: 1px;
                height: 68px;
                background: #dfe1e6;
                margin-top: 5px;
              }
              .test-infomation-right {
                flex: 1;
                margin-left: 30px;
                padding-top: 6px;
                ul {
                  display: flex;
                  justify-content: space-between;
                  li {
                    display: flex;
                    flex-direction: column;
                     /deep/.block{
                      .el-rate__item{
                       .el-rate__icon{
                          font-size: 25px;
                          margin: 0px;
                        }
                      }
                    }
                    p {
                      font-size: 14px;
                      font-weight: 400;
                      color: #575d6c;
                      line-height: 22px;
                      display: block;
                      &:nth-child(2) {
                        font-size: 30px;
                        margin-top: 10px;
                        color: rgba(37, 43, 58, 1);
                        span {
                          font-size: 14px;
                          font-weight: 400;
                          color: #575d6c;
                        }
                      }
                    }
                  }
                }
              }
            }
            .List_left {
              display: flex;

              img {
                width: 47px;
                height: 50px;
              }
              div {
                p {
                  &:last-child {
                    margin-top: 8px;
                    font-size: 14px;
                    color: #999;
                  }
                }
              }
            }
            button {
              font-size: 14px;
              background: rgba(255, 51, 56, 0.1);
              border: 1px solid rgba(255, 51, 56, 0.3);
              color: #333;
              line-height: 12px;
              padding: 9px 20px;
              border-radius: 4px;
              cursor: pointer;
            }
          }
        }
        .more {
          // border-top: 1px solid #ccc;
          padding-top: 30px;
          // background: #fff;
          .click-more {
            cursor: pointer;
            width: 150px;
            border: 1px solid rgba(255, 51, 56, 0.3);
            padding: 5px 10px;
            color: #df3833;
            text-align: center;
            font-size: 14px;
            margin: auto;
          }
          .none {
            text-align: center;
            color: #ccc;
          }
        }
      }
    }
    .home_right {
      display: none;
      height: 100%;
      width: 300px;
      .right-img {
        width: 300px;
        height: 156px;
        display: block;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .all-num {
        text-align: center;
        line-height: 12px;
        font-size: 12px;
        color: #666;
        padding: 15px 0;
        background: #fff;
      }
      .right_subConMode {
        height: 90px;
        background: #fff;
        margin: 10px 0;
        ul {
          display: flex;

          flex-direction: row;
          flex-wrap: nowrap;
          li {
            height: 70px;
            width: 100px;
            vertical-align: bottom;
            position: relative;
            cursor: pointer;
            a {
              display: block;
              text-align: center;
              line-height: 12px;
              font-size: 12px;
              color: #666;
              &:hover {
                color: #df3833;
              }

              i {
                width: 25px;
                height: 30px;
                display: block;
                margin: 16px auto 10px;
              }
            }
            &:nth-child(1) {
              i {
                background: url(../../assets/image/4.png) center no-repeat;
                background-size: cover;
              }
            }
            &:nth-child(2) {
              i {
                width: 35px;
                height: 30px;
                background: url(../../assets/image/5.png) center no-repeat;
                background-size: contain;
              }
            }
            &:nth-child(3) {
              i {
                background: url(../../assets/image/6.png) center no-repeat;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
  /deep/.el-dialog__wrapper {
    .el-dialog {
      background: url("../../assets/image/index0.png") no-repeat center;
      background-size: 100%;
      .el-dialog__body {
        .testType {
          display: flex;
          justify-content: space-around;
          div {
            text-align: center;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>